import { Controller } from "@hotwired/stimulus"
import Quill from "quill"
import Choices from "choices.js";

export default class extends Controller {

  static targets = [ "modal", "content", "status", "quill", "note" ]

  connect() {
    if(this.hasStatusTarget){
      new Choices(this.statusTarget, {
        searchEnabled: false
      });
    }

    if(!Quill.find(this.quillTarget)) {
      let quill = new Quill(this.quillTarget, {
        theme: 'snow'
      });
      let note = this.noteTarget;
      note.value = quill.container.firstChild.innerHTML;
      quill.on('text-change', function(delta, oldDelta, source) {
        note.value = quill.container.firstChild.innerHTML;
      });
    }
  }

  hideModal() {
    this.element.parentElement.removeAttribute("src") // it might be nice to also remove the modal SRC
    this.modalTarget.remove()
  }

  // hide modal on successful form submission
  // action: "turbo:submit-end->turbo-modal#submitEnd"
  submitEnd(e) {
    console.log(e)
    if (e.detail.success) {
      this.hideModal()
    }
  }

  // hide modal when clicking ESC
  // action: "keyup@window->turbo-modal#closeWithKeyboard"
  closeWithKeyboard(e) {
    if (e.code == "Escape") {
      this.hideModal()
    }
  }

  // hide modal when clicking outside of modal
  // action: "click@window->turbo-modal#closeBackground"
  closeBackground(e) {
    if (e && this.contentTarget.contains(e.target)) {
      return
    }
    this.hideModal()
  }
}
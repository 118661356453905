import { Controller } from "@hotwired/stimulus"
import Chart from 'chart.js/auto';

export default class extends Controller {

  static targets = [ "refund", "gray" ]
  static values = {
    labels: Array,
    refund: Object,
    gray: Object
  }

  connect() {
    if(this.hasRefundTarget) {
      this.showRefundLine(this.refundTarget, this.labelsValue, this.refundValue, 'Refund Trends');
    }

    if(this.hasGrayTarget) {
      this.showRefundLine(this.grayTarget, this.labelsValue, this.grayValue, 'Gray Orders Trends');
    }
  }

  showRefundLine(el, labels, refund, title) {
    let datasets = []
    for (const [key, value] of Object.entries(refund)) {
      datasets.push({
        label: key,
        data: value,
        fill: false,
        borderColor: this.random_rgba(),
        tension: 0.2
      })
    }

    const data = {
      labels: labels,
      datasets: datasets
    };

    new Chart(el, {
      type: "line",
      data: data,
      options: {
        responsive: true,
        plugins: {
          legend: {
            position: 'top',
          },
          title: {
            display: true,
            text: title
          }
        }
      },
    })
  }

  random_rgba() {
    var o = Math.round, r = Math.random, s = 255;
    return 'rgba(' + o(r()*s) + ',' + o(r()*s) + ',' + o(r()*s) + ',' + r().toFixed(1) + ')';
  }

}
import { Controller } from "@hotwired/stimulus"
import Choices from "choices.js";

export default class extends Controller {

  static targets = [ "modal", "content", "hide", "zones", "preferences", "calculatorType", "calculatorId" ]

  connect() {
      new Choices(this.zonesTarget, {
        searchEnabled: true,
        removeItemButton: true
      });

      this.initial_cal_type = this.calculatorTypeTarget.value;

  }

  changeCalculatorType(e) {
    if(e.target.value != this.initial_cal_type) {
      this.calculatorIdTarget.disabled = true;
    } else {
      this.calculatorIdTarget.disabled = false;
    }
    
    const csrf_token = document.querySelector('meta[name=csrf-token]').getAttribute('content');
    if(e.target.dataset.url) {
      fetch(e.target.dataset.url, {
        method: "POST",
        headers: { 'Accept': "text/vnd.turbo-stream.html", 'X-CSRF-Token': csrf_token, 'Content-Type': 'application/json' },
        body: JSON.stringify({ calculator: e.target.value }),

      }).then (response => response.text())
      .then(html => Turbo.renderStreamMessage(html));
    }
  }

  hideModal() {
    this.element.parentElement.removeAttribute("src") // it might be nice to also remove the modal SRC
    this.modalTarget.remove()
  }

  // hide modal on successful form submission
  // action: "turbo:submit-end->turbo-modal#submitEnd"
  submitEnd(e) {
    if (e.detail.success) {
      this.hideModal()
    }
  }

  // hide modal when clicking ESC
  // action: "keyup@window->turbo-modal#closeWithKeyboard"
  closeWithKeyboard(e) {
    if (e.code == "Escape") {
      this.hideModal()
    }
  }

  // hide modal when clicking outside of modal
  // action: "click@window->turbo-modal#closeBackground"
  closeBackground(e) {
    if (e && this.contentTarget.contains(e.target)) {
      return
    }
    this.hideModal()
  }
}
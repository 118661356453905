import { Controller } from "@hotwired/stimulus"
import { getMetaValue } from "./helpers"
import * as bootstrap from 'bootstrap'

export default class extends Controller {

	static targets = ["modal", "flash", 'search', 'searchBtn', 'checkbox', 'sku', 'image', 'title', 'quantity', 'modalProducts',
		'products', 'mainProduct', 'mainSku', 'mainQuantity', 'deleteProduct', 'incomeType', 'incomeType1', 'incomeType2']

	connect() {
		// {sku , quantity, boxNo, title}
		this.products = [];
		this.modalProducts = [];
		this.modal = bootstrap.Modal.getOrCreateInstance(document.getElementById('eccangAsnModal'));
	}

	search(event) {
		event.preventDefault();

		let sku = this.searchTarget.value;
		let url = this.searchBtnTarget.dataset.url;
		if(sku) {
			fetch(url, {
				method: "POST",
				headers: { 'Content-Type': 'application/json', 'Accept': "text/vnd.turbo-stream.html", 'X-CSRF-Token': getMetaValue("csrf-token") },
				body: JSON.stringify({ sku: sku }),
			}).then(response => response.text())
				.then(html => Turbo.renderStreamMessage(html));
		}
	}

	toggleProduct(event) {
		let selected_sku = event.target.dataset.sku
		let exist_product_index = this.modalProducts.findIndex((product) => {
			return product.ec_product_sku == selected_sku
		});

		if (event.target.checked) {
			let index = this.checkboxTargets.indexOf(event.target);
			let quantity = this.quantityTargets[index].value;
			let image = this.imageTargets[index] ? this.imageTargets[index].getAttribute('src') : null;
			let title = this.titleTargets[index].textContent;

			let product_data = {
				ec_product_sku: selected_sku,
				quantity: parseInt(quantity),
				boxNo: 1,
				image: image,
				title: title
			}
			if (exist_product_index >= 0) {
				this.modalProducts[exist_product_index] = product_data;
			} else {
				this.modalProducts.push(product_data);
			}
		} else {
			if (exist_product_index >= 0) {
				this.modalProducts.splice(exist_product_index, 1);
			}
		}
	}

	updateQuantity(event) {
		let index = this.quantityTargets.indexOf(event.target);
		let sku = this.skuTargets[index].dataset.sku;
		let exist_product_index = this.modalProducts.findIndex((product) => {
			return product.ec_product_sku == sku
		});
		this.modalProducts[exist_product_index].quantity = parseInt(event.target.value)
	}

	updateMainQuantity(event) {
		let index = this.mainQuantityTargets.indexOf(event.target);
		let sku = this.mainSkuTargets[index].dataset.sku;
		let exist_product_index = this.products.findIndex((product) => {
			return product.ec_product_sku == sku
		});
		this.products[exist_product_index].quantity = parseInt(event.target.value)
	}

	addProducts(event) {
		if (this.modalProducts.length == 0) {
			this.flashTarget.textContent = "Please select at least one product"
		} else {
			let emptyProducts = this.modalProducts.filter((p) => !p.quantity)
			if (emptyProducts.length > 0) {
				this.flashTarget.textContent = "Please input product quantity"
			} else {
				this.modal.hide();
				this.mergeProducts();
				this.renderProducts();
				this.modalProducts = [];
			}
		}
	}

	showModal(event) {
		this.modalProductsTarget.innerHTML = "";
		this.searchTarget.value = "";
		this.flashTarget.textContent = "";
		this.modal.show();
		this.searchTarget.focus();
	}

	mergeProducts() {
		for (const modalProduct of this.modalProducts) {
			let modalSku = modalProduct.ec_product_sku;
			let exist_product_index = this.products.findIndex((p) => p.ec_product_sku == modalSku)
			if (exist_product_index >= 0) {
				this.products[exist_product_index].quantity += modalProduct.quantity;
			} else {
				this.products.unshift(modalProduct);
			}
		}
	}

	renderProducts() {
		let htmlContent = ""
		for (const product of this.products) {
			htmlContent += this.renderRow(product)
		}
		this.productsTarget.innerHTML = htmlContent;
	}

	removeProduct(event) {
		event.preventDefault();

		let deleted_sku = event.target.dataset.sku
		let exist_product_index = this.products.findIndex((product) => {
			return product.ec_product_sku == deleted_sku
		});
		if (exist_product_index >= 0) {
			this.products.splice(exist_product_index, 1);
		}
		let index = this.deleteProductTargets.indexOf(event.target);
		this.mainProductTargets[index].remove()
	}

	renderRow(product) {
		return `<tr data-eccang-asn-target="mainProduct">
      <td><input type="hidden" name="eccang[items[][box_no]]" value="1">1</td>
      <td><img src="${product.image}" ></td>
      <td data-eccang-asn-target="mainSku" data-sku="${product.ec_product_sku}"><input type="hidden" name="eccang[items[][product_sku]]" value="${product.ec_product_sku}">${product.ec_product_sku}</td>
      <td class="text-wrap">${product.title}</td>
      <td><input type="text" class="form-control" name="eccang[items[][quantity]]" value="${product.quantity}" data-eccang-asn-target="mainQuantity" data-action="eccang-asn#updateMainQuantity"></td>
      <td><a href="#" data-eccang-asn-target="deleteProduct" data-action="eccang-asn#removeProduct" data-sku="${product.ec_product_sku}">delete</a></td>
    </tr>`
	}

	setIncomeType(event) {
		let incomeType = event.target.dataset.incometype;
		this.incomeTypeTarget.value = incomeType;
		if (incomeType == "0") {
			this.incomeType1Target.setAttribute("class", "badge bg-gradient-success");
			this.incomeType2Target.setAttribute("class", "badge bg-gradient-light");
		} else {
			this.incomeType1Target.setAttribute("class", "badge bg-gradient-light");
			this.incomeType2Target.setAttribute("class", "badge bg-gradient-success");
		}
	}
}
